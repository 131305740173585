<template>
  <div id="setting">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />修改密码
        </van-col>
        <van-col
          span="14"
          class="header-title"
          style="text-align: right"
          @click="onUpdateClick"
        >
          确认修改
        </van-col>
      </van-row>
    </div>
    <div class="setting-container">
      <div class="setting-body">
        <!-- <van-cell center :border="true" value="头像" size="large" is-link>
          <template #right-icon>
            <van-uploader
              max-count="1"
              
              accept="image/*"
              :after-read="fnUploadRequest"
            >
              <van-image width="40" height="40" round :src="user.avatar" />
            </van-uploader>
            <van-icon name="arrow" />
          </template>
        </van-cell> -->
        <!-- <van-cell
          :clickable="false"
          disabled
          center
          :border="true"
          value="用户名称"
          size="large"
          is-link
        >
          <template #right-icon>
            <input
              class="cell-input"
              v-model="user.username"
              type="text"
              placeholder="请输入用户名"
            />
            <van-icon name="arrow" />
          </template>
        </van-cell> -->
        <van-cell
          disabled
          :clickable="false"
          center
          :border="true"
          value="旧密码"
          size="large"
          is-link
        >
          <template #right-icon>
            <input
              class="cell-input"
              v-model="user.old_password"
              type="password"
              placeholder="请输入旧密码"
            />
            <van-icon name="arrow" />
          </template>
        </van-cell>
        <van-cell
          disabled
          :clickable="false"
          center
          :border="true"
          value="新密码"
          size="large"
          is-link
        >
          <template #right-icon>
            <input
              class="cell-input"
              v-model="user.password"
              type="password"
              placeholder="请输入新密码"
            />
            <van-icon name="arrow" />
          </template>
        </van-cell>

        <van-cell
          :clickable="false"
          center
          :border="true"
          value="确认密码"
          size="large"
          is-link
        >
          <template #right-icon>
            <input
              class="cell-input"
              v-model="user.password_confirmation"
              type="password"
              placeholder="请输入确认密码"
            />
            <van-icon name="arrow" />
          </template>
        </van-cell>

        <div class="btn" @click="onUpdateClick">确认修改</div>
      </div>
    </div>
  </div>
</template>

<script>
import cookies from "../../utils/cookies";
import oss from "@/utils/oss";
import { Toast } from "vant";
export default {
  name: "setting-item",
  data() {
    return {
      refreshing: false,
      loading: true,
      user: {
        username: "",
        avatar: "",
        mobile: "",
        password: null,
        old_password: null,
        password_confirmation: null,
      },
      repassword: null,
      list: [],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubUsersClick() {
      this.$router.push("/setting/sub-users");
    },
    async onUpdateClick() {
      if (!this.user.old_password) {
        Toast("请输入旧密码");
        return false;
      }
      if (!this.user.password) {
        Toast("请输入新密码");
        return false;
      }
      if (!this.user.password_confirmation) {
        Toast("请确认密码");
        return false;
      }
     
      const user_id = this.user_id;
      const res = await this.$apis.edit_sub_user(user_id, this.user);
      if (res.status == 200) {
        Toast("修改成功!");
        this.$router.go(-1);
      }
    },
    async get_sub_users() {
      const res = await this.$apis.get_sub_users();
      this.list = res.data;
      this.user.username = this.list[this.$route.query.index].username;
      this.user.avatar = this.list[this.$route.query.index].avatar;
      this.user.mobile = this.list[this.$route.query.index].mobile;
      this.user_id = this.list[this.$route.query.index].id;
    },
    fnUploadRequest(option) {
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        _self.user.avatar = res.fileUrl[0].split("?")[0];
        Toast.clear();
      });
    },
  },
  components: {},
  created() {
    this.get_sub_users();
  },
};
</script>

<style scoped>
#setting {
  height: 100vh;
  /* background: #f3f3f3; */
  padding-top:10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.setting-container {
  /* padding: 0.2rem; */
  /* height: 90vh;
  overflow-y: scroll;
  padding-bottom: 1.2rem; */
  /* width: 90%; */
  padding: 0.3rem;
}
.setting-body {
  background: #ffff;
  /* height: 90vh; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  /* padding: 0.3rem; */
  overflow-y: scroll;
  /* padding-bottom: 1.2rem; */
}
.setting-item {
  height: 1rem;
  line-height: 1rem;
}
.setting-item-title {
  color: #333;
  font-size: 0.3rem;
  text-align: left;
}
.setting-item-value {
  color: #333;
  font-size: 0.3rem;
  text-align: right;
}
.cell-input {
  height: 0.5rem;
  line-height: 0.5rem;
  padding: 0 10px;
  text-align: right;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  margin-top: 0.4rem;
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
